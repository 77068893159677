.login__paper{
  width: 68%;
  max-height: 800px;
  padding: 90px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.5);
  position: relative;
  min-height: 780px;
  background-image: url("./../../svgs/▶ SECDOC.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;

  &--form{
    left: 0;
    width: 100%;
    position: relative;

    &--title{
      color: #095073;
      line-height: 66px;
      text-transform: uppercase;
      top: 99px;
      left: 92px;
    }
  }
  &--form-section{
    margin-top: 30px;
    padding: 12px;
    width: 400px;
  }
}

.form{
  padding: 16px;
  &__header{
    color: #1A202C;
    font-size: 30px;
    font-weight: 700;
  }

 & form{
   margin-top: 28px;
   width: 100%;
 }

& form  .form__label{
  color: #4A5568;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 12px;
  display: inline-block;
}
}


.login-actions{
  & .remember{
    color: #2D3748;
    margin-left: 6px;

    &:hover{
      cursor: pointer;
    }
  }
}

