* {
    margin: 0;
    padding: 0;
}

html,
body,
#root,
.app,
.content {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    max-height: 100vh;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    position: relative;
}

code {
    font-family: 'Roboto', sans-serif;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #e0e0e0;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar-track:hover {
    background: #555;
}

a:link {
    text-decoration: none;
}

a:hover {
    cursor: pointer;
}

a:active {
    color: #2c5282;
}

.container {
    height: 100%;
    width: 100%;
    color: #000;
    padding: 0 64px;
    display: flex;
    align-items: center;
}
