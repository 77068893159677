.content{

  .appointment{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    overflow-y: scroll;
    transition: all 5s;
    &::-webkit-scrollbar {
      display: none;
    }

    &__container{
      max-height: 100%;
      height:704px;
      width: 100%;
      max-width: 624px;
      margin-top: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }

      &--content{
        width: 100%;
        max-width: 624px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 12px;

        h6{
          font-weight: 500;
          color: #0D0E13;
          font-family: Montserrat, sans-serif;
          font-size: 20px;
        }

        .card{
          padding: 16px 20px;
          border-radius: 12px;
          box-shadow: 0 0 16px 0 #0000000D;
          max-height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .errorText{
            display: flex;
            & img{
              margin-right: 12px;
            }
            & h6{
              font-weight: 400;
              color:#000;
              font-size: 16px;
              font-family: Montserrat, sans-serif;
            }
          }

          .notfound{
            display: flex;
            justify-content: space-between;
            & div{
              width: 49%;
            }
          }

          &__header{
            h6{
              color: #0D0E13;
              font-size: 14px;
              font-weight: 500;
              font-family: 'Montserrat', sans-serif;
            }
          }

        }
      }
      &--result{
        h6{
          font-weight: 500;
          color: #0D0E13;
          font-family: Montserrat, sans-serif;
          font-size: 20px;
          margin-bottom: 18px;
        }
        width: 100%;
        .card.list{
          width: 100%;
          padding: 16px 20px;
          border-radius: 12px;
          box-shadow: 0 0 16px 0 #0000000D;
          overflow-y: scroll;
          max-height: 400px;
          &::-webkit-scrollbar {
            display: none;
          }


          & .item{
            border: 1px solid #E6EDF2;
            padding: 16px;
            height: 48px;
            border-radius: 8px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &:hover{
              cursor: pointer;
            }

            &:nth-child(even){
              margin: 12px 0;
            }

            h6{
              font-size: 14px;
              margin: 0;
              color: #0D0E13
            }

          }
        }

      }

    }
  }
}