.content{
   .registration{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 90vh;
      overflow-y: scroll;
      &::-webkit-scrollbar {
         display: none;
      }

      &__container{
         min-height:704px;
         width: 624px;
         max-width: 100%;
         margin-top: 102px;

         &--form{
            box-shadow: 0 0 4px rgba(0, 0, 0, 0.04), 0 0 16px rgba(0, 0, 0, 0.05);
            padding: 20px;
            margin-top: 12px;
            border-radius: 12px;

            .buttons{
               display: flex;
               margin-top: 32px;
               justify-content: right;

               &__cancel{
                  margin-right: 12px;
               }
            }
            .title{
               margin: 22px 0;
               font-weight: 500;
               font-size: 20px;
               color: #0D0E13;
            }

            .additional-information{
               margin-top: 22px;
               .label{
                  margin-bottom: 8px;
                  color: #0D0E13;
                  font-size: 14px;
                  letter-spacing: 0.01em;
                  font-weight: 500;
               }
            }

            .personal-data{
               .title{
                  color:#0D0E13;
                  font-size: 14px;
                  letter-spacing: 0.01em;
               }
               .fields{
                  margin-bottom: 48px;
                  &__container{
                     display: flex;
                     justify-content: space-between;
                     margin-top: 18px;

                     &--field{
                        min-width: 282px;
                        margin-top: 12px;
                     }
                  }

               }
            }
         }
      }

   }
}