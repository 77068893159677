.card {
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 0 16px 0 #0000000D;

  & .form-field:not(:first-child) {
    margin-top: 22px;
  }

  & .actions {
    display: flex;
    justify-content: space-between;
    margin-top: 22px;

    .notfound {
      display: flex;
      justify-content: space-between;
      width: 100%;

      & div {
        width: 49%;
      }

      & .actions__button {
        width: 100%;
      }
    }
  }
}