.appointmentForm{

  &__container{
    width: 800px;
    &--field{
         display: flex;
         justify-content: space-between;
         margin-bottom: 22px;
      align-items: center;
      & .label{
        width: 32%;
        color: #0D0E13;
        font-size: 16px;
        font-family: 'Roboto', sans-serif;
      }
    }
  }

}