.action{
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
 align-items: center;
  box-shadow: 0 3px 8px 0 #0000001F;
  border-radius: 5px;
  & img{
    height: 28px;
  }

  &:hover{
    cursor: pointer;
  }

}