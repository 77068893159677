.content{
  .documents{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    height: 90vh;


    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    &__container{
      width: 100%;
      max-width: 100%;
      margin-top: 102px;
      display: flex;
      flex-direction: column;
      align-items: center;

      & h6{
        color: #0D0E13;
        font-size: 20px;
        font-weight: 500;
        font-family: 'Montserrat', sans-serif;
      }

      & .table{
        margin-top: 28px;
      }

    }
  }
}
