.documentCreatePage {
  display: flex;
  justify-content: center;
  overflow-y: scroll;
  height: 90vh;

  &::-webkit-scrollbar {
    display: none;
  }

  & .document-create {
    width: 624px;
    height: 900px;
    margin: 64px 0;

    &__container {

      & .page-title {
        margin-bottom: 22px;

        & h6 {
          font-family: 'Montserrat', sans-serif;
          font-size: 20px;
          font-weight: 500;
          color: #0D0E13;
        }

      }

      & .step-title {
        margin-bottom: 12px;

        h6 {
          font-weight: 500;
          font-size: 20px;
          font-family: 'Roboto', sans-serif;
        }
      }

      & .card {
        padding: 20px;
        border-radius: 12px;
        box-shadow: 0 0 16px 0 #0000000D;

        & .form-field:not(:first-child) {
          margin-top: 22px;
        }

        & .actions {
          display: flex;
          justify-content: space-between;
          margin-top: 22px;

          .notfound {
            display: flex;
            justify-content: space-between;
            width: 100%;

            & div {
              width: 49%;
            }

            & .actions__button {
              width: 100%;
            }
          }
        }
      }

      & .actions_end {
        justify-content: center !important;
      }
    }


  }
}