.patientForm {
  border-radius: 12px;
  box-shadow: 0 0 16px 0 #0000000D;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > * {
    margin-top: 16px;
  }

  & .actions{
    display: flex;
    justify-content: space-between;
    &__button{
      margin-top: 12px;
      width: 100%;
    }
  }


}

.listItemText{
  color: #0D0E13;
  font-size: 14px;
  transition: all .3s;

  &:hover{
    cursor: pointer;
  }
}