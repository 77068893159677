$primary: #E2F5FA;
@import "../../../node_modules/bootstrap/scss/bootstrap";

.calender-filters{
  margin-bottom: 28px;
  margin-top: 12px;
  border: 1px solid var(--bs-gray-400);
  box-shadow: 0 1px 1px rgba(0,0,0,.05);

  &__header{
    background-color: #f5f5f5;
    padding: 8px;
    border-color: #ddd;
    & h6{
      font-size: 16px;

    }
  }

  &__params{
   padding: 8px;
    display: flex;
    justify-content: space-between;
    & .users{
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    & .event-type{
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }


}