.content{
  .patient{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    &__actions{
      position: absolute;
      top: 5px;
      right: 15px;
      width: 265px;
      max-width: 265px;
      bottom: 15px;
      overflow-y: scroll;
      max-height: 90vh;
      padding: 28px 12px;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__container{
      height:704px;
      width: 624px;
      max-width: 100%;

      &--title{
        h6{
          font-size: 20px;
          font-weight: 500;
          font-family: "Roboto", serif;
          margin-bottom: 12px;
        }
      }

      &--card{
        height: 600px;
        max-height: 600px;
        border-radius: 12px;
        box-shadow: 0 0 16px 0 #0000000D;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        & .actions{
          display: flex;
          justify-content: space-between;
          &__button{
            margin-top: 12px;
            width: 100%;
          }
        }


      }

    }
  }
}

.listItemText{
  color: #0D0E13;
  font-size: 14px;
  transition: all .3s;

  &:hover{
    cursor: pointer;
  }
}