.notfoundPage{
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  &__content{
    background-color: #42B3E5;
    height: 100%;
    max-height: 600px;
    clip-path: polygon(0 0, 56% 0, 100% 100%, 0% 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    color: #fff;
    font-size: 32px;
    width: 90%;

    & h4{
      margin-bottom: 12px;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 32px;
    }

    &--link{
      margin-top:38px;
      font-size: 24px;
     & span{
       & .icon{
         margin-right: 12px;
       }
     }
    }


  }
}