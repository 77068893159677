.profile{
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__information{
     display: flex;
    justify-content: space-between;
    align-items: center;

    &--photo{
      margin-right: 8px;
    }
    &--basic-info{
      display: flex;
      flex-direction: column;
      width: 160px;
      max-width: 160px;
      padding:0 4px;
      overflow: hidden;


      & .name{
        font-size: 14px;
        font-weight: 500;
        color: #202020;
        font-family: 'Inter', sans-serif;
        line-height: 17px;
      }
      & .position{
        color: #898989;
        font-size: 14px;
        line-height: 15px;
      }
      &:hover{
        cursor: pointer;
      }

    }
  }
}