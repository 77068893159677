.home {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 12px;


  &__cards {
    width: 100%;
    //display: flex;
    //overflow-y: hidden;
    //overflow-x: scroll;
    //
    //& ::-webkit-scrollbar {
    //  display: initial;
    //}
    //
    //& ::-webkit-scrollbar {
    //  width: initial;
    //}
    //
    //& ::-webkit-scrollbar-track {
    //  background: initial;
    //}
    //
    //& ::-webkit-scrollbar-thumb {
    //  background: initial;
    //}
    //
    //&::-webkit-scrollbar-track:hover {
    //  background: initial;
    //}

    &List {
      display: flex;
      overflow-x: scroll;
      width: 100%;
      padding-bottom: 24px;

      //&::-webkit-scrollbar {
      //  all: unset;
      //}

      &::-webkit-scrollbar {
        display: initial;
      }

      &::-webkit-scrollbar {
        width: initial;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }

      &::-webkit-scrollbar-thumb {
        background: #F0F1F6;
        opacity: 0.5;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #F0F1F6;
        opacity: 1;
      }

      &::-webkit-scrollbar-track:hover {
        background: initial;
      }
    }
  }
}