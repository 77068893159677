.content{
  .patient{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    &__actions{
      position: absolute;
      top: 5px;
      right: 15px;
      width: 265px;
      max-width: 265px;
      bottom: 15px;
      overflow-y: scroll;
      max-height: 90vh;
      padding: 28px 12px;
      &::-webkit-scrollbar {
        display: none;
      }
    }

    &__container{
      height:704px;
      width: 624px;
      max-width: 100%;

      &--title{
        h6{
          font-size: 20px;
          font-weight: 500;
          font-family: "Roboto", serif;
          margin-bottom: 12px;
        }
      }

    }
  }
}

.listItemText{
  color: #0D0E13;
  font-size: 14px;
  transition: all .3s;

  &:hover{
    cursor: pointer;
  }
}