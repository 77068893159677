.content{
  .procedures{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    &__container{
      min-height:704px;
      width: 624px;
      max-width: 100%;
      margin-top: 102px;

    }
  }
}