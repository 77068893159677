.content {
  .profile {

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    &__container {
      min-height: 796px;
      width: 956px;
      max-width: 100%;
      margin-top: 102px;
      padding: 16px;
      //border: 1px solid red;

      &--details{
        display: flex;
        justify-content: space-between;

        .profile-details, .work-details{
          height: 100%;


          .header{
            height: 100%;
            color: #0D0E13;
            line-height: 32px;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 12px;
          }

          .info{
            padding: 12px;
            border-radius: 12px;
            max-height: 748px;
            min-height: 533px;
            box-shadow: 0 0 16px 0 rgba(0, 0, 0, 0.05);
            display: flex;
            flex-direction: column;
            .bio{
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              margin-top: 32px;
              .name{
                margin-top: 22px;
                font-size: 16px;
                font-weight: 500;
                color: rgba(13, 14, 19, 1);
                padding: 2px
              }

              .dob{
                margin-top: 12px;
                h6{
                  color: #0D0E13;
                  font-size: 14px;
                  font-weight: 400;
                  background-color: #F9FAFB;
                  padding: 12px;
                  border-radius: 6px;
                }
              }
            }

            .patients{
              margin-top: 16px;
              display: flex;
              flex-direction: column;
              align-items: center;

              .number{
                font-size: 16px;
                line-height: 24px;
                font-weight: 500;
                color:#0D0E13;
              }
              .text{
               color: #7A828A;
                font-weight: 400;
              }
            }

            .work-time{
              margin-top: 24px;
              background-color: #EDFCDC;
              padding: 10px 16px;
              border-radius: 6px;
              &__text{
                color: #0D0E13;
                font-size: 12px;
                span{
                  margin-right: 12px;
                }
              }
            }
            .work-place{
              margin-top: 28px;
              .header{
                font-size: 12px;
                font-weight: 400;
                color:#0D0E13;
              }
              &__data{
                display: flex;
                flex-wrap: wrap;

                h6{
                  font-size: 14px;
                  margin: 4px;
                  font-weight: 400;
                  text-align: center;
                  color: #3675F1;
                  background-color: #EBF4FE;
                  padding: 9px 12px;
                  border-radius: 6px;
                }
              }
            }
            .edit-info{
              margin-top: 22px;
              margin-bottom: 12px;

              button{
                background-color: #fff;
                border: 0;
                color: #0D0E13;
                font-size: 15px;
                font-weight: 400;
                display: flex;
                align-items: center;
                line-height: 24px;
                letter-spacing:-0.24%;

                span{
                  margin-right: 24px;
                }

                &:hover{
                  cursor: pointer;
                }
              }
            }
          }
        }

        .profile-details{
          width: 30%;
        }

        .work-details{
          width: 65%;

          & .info{
             display: flex;
            flex-direction: column;
            justify-content: space-between;

            & .personal_data--buttons{
              align-self: flex-end;
            }

            & .personal_data--fields,
            & .work_data--fields {
              margin: 18px 0;
             & > h6{
                color: #0D0E13;
                size: 20px;
                font-weight: 500;
                line-height: 32px;
                margin: 12px 0;
              }

              & .additional-information{
                margin-top: 16px;
              }
            }

            & .personal_data--fields{
              & .fields__container{
                 display: flex;
                justify-content: space-between;
                margin-top: 12px;

                & .fields__container--field{
                  width: 100%;
                  margin-top: 12px;
                  min-width: 282px;

                  &:first-child{
                    margin-right: 20px;
                  }
                }
              }
            }
          }
        }

        .logout{
          margin-top: 32px;
          display: flex;
          background-color: #fff;
          border: 0;
          font-size:14px;
          color: #0D0E13;
          font-weight: 400;
          align-items: center;
          padding: 8px 0;
          &:hover{
            cursor: pointer;
          }
          span{
            margin-right: 12px;
          }

        }
      }
    }
  }
}