.content{
  .calender{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 90vh;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      display: none;
    }

    &__container{
      min-height:704px;
      width: 80%;
      max-width: 100%;
      margin-top: 140px;
      position: relative;

      &--title{
        display: flex;
        justify-content: center;
        margin-bottom: 18px;
        & h6{
          color: #0D0E13;
          font-family: 'Montserrat', sans-serif;
          font-size: 20px;
          font-weight: 500;

        }
      }

    }
  }
}