.appbar{
   display: flex;
   justify-content: space-between;
   width: 100%;
   height: 100%;
   align-items: center;

 a:link,
 a:visited{
    color: #000;
   font-size: 14px;
   font-weight: 700;
   font-family: 'Montserrat', sans-serif;
  }
}